/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');




body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', 'Courier New', monospace;

}



.MainFicheFamille{
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: left;
  gap:40px;
  flex-wrap: wrap;
}

.ContainerFicheFamille{
  flex-basis: 400px;
}

.ContainerFicheFamille img{
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
  width: 100%;
}

.ItemFicheFamille{
  margin: 20px;
}

.MainHeader{
  max-width: 1000px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  padding: 40px 20px;
}

.ContainerHeader{
  flex-basis: 300px;
}


.ContainerHeader a{
  background-color: #006ec7;
  color: white;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 30px;
  border-radius: 20px;
  transition: background-color 1s ease, transform 1s ease;
}

.ContainerHeader a:hover{
  background-color: #349720;
  transform: translateY(-5px); /* Déplace légèrement vers le haut */

}


.ContainerHeader:last-of-type{
  text-align: right;
}


.ContainerHeader button{
  background-color: #DC2626;
  color: white;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 30px;
  border-radius: 20px;
  transition: background-color 1s ease, transform 1s ease;
}

.ContainerHeader button:hover{
  background-color: #bd00c7;
  transform: translateY(-5px); /* Déplace légèrement vers le haut */

}


.MainContact{
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 900px;
  margin: auto;
  padding: 0 20px 50px 20px;
}


.NoBackground a{
  background-color: transparent;
}

.NoBackground a:hover{
  background-color: transparent;
  transform: none;
}

#BoutonInstall button{
  background-color: green;
  padding: 15px 20px;
  color: white;
  border-radius: 20px;
}

#BoutonInstall button:hover{
  background-color: rgb(46, 129, 46);

}

.MainForm{
  max-width: 900px;
  margin: auto;
}

.LogoHead{
  margin-bottom: 30px;
}



.MainCode{
  max-width: 1180px;
  margin: auto;
  padding: 50px 20px 0 20px;
}

.MainCode h2{
  font-size: 20px;
  font-weight: 600;
}







@media only screen and (max-width: 700px) {

  .MainHeader{
    justify-content: center;
  }


  .ContainerHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Prendre toute la largeur */
  }

  .ContainerHeader a{
    display: block; /* Transforme les liens en blocs */
    width: 100%; /* Le lien occupe toute la largeur */
    text-align: center; /* Centre le texte à l'intérieur */
  }

  .ContainerHeader:first-of-type {
order: 2;
  }

  .ContainerHeader:last-of-type {
    order: 3;
      }
}



